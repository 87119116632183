"use client";
import React, { useState, useEffect, useRef } from 'react';

import { Container, Grid, Card, CardMedia, CardContent, Box, Fab, Pagination, ToggleButtonGroup, ToggleButton, Backdrop, CircularProgress  } from '@mui/material';
import CameraIcon from '@mui/icons-material/VideoCameraFront';
import CameraAlt from '@mui/icons-material/CameraAlt';

import VideoFrameSelectionDialog from './components/VideoFrameSelectionDialog';
import VirtualTryOnDialog from './components/VirtualTryOnDialog';
import { callApiGetGarments } from './common/api';

// interface Product {
//   id: string;
//   name: string;
//   category: string;
//   price: string;
//   image_url: string;
// }

const ProductCard: React.FC<{ product: any, onClickVirtualTryOnWithCamera: any, onClickVirtualTryOnWithPhoto: any }> = ({ product, onClickVirtualTryOnWithCamera, onClickVirtualTryOnWithPhoto }) => (
  <Card>
    <CardMedia
      component="img"
      width="100"
      image={product.garments[0].image_url}
      alt={product.garments[0].name}
    />
    <CardContent>
      <Box display="flex" justifyContent="space-between" paddingX={1}>
        <Fab onClick={onClickVirtualTryOnWithCamera} color="primary" aria-label="camera-icon">
          <CameraIcon />
        </Fab>
        <Fab onClick={onClickVirtualTryOnWithPhoto} color="primary" aria-label="camera-alt">
          <CameraAlt />
        </Fab>
      </Box>
      <Box style={{display: 'flex', flexDirection: 'column', borderWidth: '1px', paddingTop: '5px'}}>
          <div>{product.garments[0].category}</div>
          <div>{product.garments[0].name}</div>
          <div>{product.garments[0].description}</div>
      </Box>
    </CardContent>
  </Card>
);

const ProductGrid: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [gender, setGender] = useState('W'); 
  const [openVideoFrameSelectionDialog, setOpenVideoFrameSelectionDialog] = useState(false);
  const [openVirtualTryOnDialog, setOpenVirtualTryOnDialog] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubjectPhoto, setSelectedSubjectPhoto] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchGarments = async (page: number = 1) => {
    setLoading(true);
    const garmentsData = await callApiGetGarments(page, pageSize, gender);
    setProducts(garmentsData.items);
    setTotalPages(garmentsData.pagination.total_pages);
    setLoading(false);
  };

  useEffect(() => {
    fetchGarments(currentPage);
  }, [currentPage, gender]);

  const handleGenderChange = (event: React.MouseEvent<HTMLElement>, newGender: string | null) => {
    if (newGender !== null) {
      setGender(newGender);
      setCurrentPage(1); // Reset to first page when switching gender
      fetchGarments(1);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const handleOnClickVirtualTryOnWithCamera = (garmentUrl: string, category: string) => {
    setSelectedImageUrl(garmentUrl);
    setSelectedCategory(category);
    setOpenVideoFrameSelectionDialog(true);
  };

  const handleOnClickVirtualTryOnWithPhoto = (garmentUrl: string, category: string) => {
    setSelectedImageUrl(garmentUrl);
    setSelectedCategory(category);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedSubjectPhoto(file);
      setOpenVirtualTryOnDialog(true);
    }
    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCloseVideoFrameSelectionDialog = () => {
    setOpenVideoFrameSelectionDialog(false);
  };

  const handleCloseVirtualTryOnDialog = () => {
    setOpenVirtualTryOnDialog(false);
    setSelectedImageUrl('');
    setSelectedCategory('');
    setSelectedSubjectPhoto(null);
  };
  
  const b64ToFile = (base64:string, fileName:string, mimeType:string) => {
    const byteString = atob(base64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([ia], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  };

  const handleSelectFrame = (frame_b64:string) => {
    let file = b64ToFile(frame_b64, 'subject_image', 'image/generic')
    setSelectedSubjectPhoto(file);
    setOpenVirtualTryOnDialog(true);
  }

  return (
    <Container sx={{ py: 8 }}>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
            <Box display="flex" justifyContent="center" mb={4}>
        <ToggleButtonGroup
          value={gender}
          exclusive
          onChange={handleGenderChange}
          aria-label="gender selection"
          color="primary"
        >
          <ToggleButton value="W" aria-label="women">
            Women
          </ToggleButton>
          <ToggleButton value="M" aria-label="men">
            Men
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={4}>
        {products.map((product: any) => (
          <Grid item key={product.id} xs={12} sm={6} md={4}>
            <ProductCard product={product} onClickVirtualTryOnWithPhoto={() => handleOnClickVirtualTryOnWithPhoto(product.garments[0].image_url, product.garments[0].category)} onClickVirtualTryOnWithCamera={() => { handleOnClickVirtualTryOnWithCamera(product.garments[0].image_url, product.garments[0].category) }} />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" />
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <VideoFrameSelectionDialog open={openVideoFrameSelectionDialog} handleSelectFrame={handleSelectFrame} handleClose={handleCloseVideoFrameSelectionDialog} selectedImageUrl={selectedImageUrl} selectedCategory={selectedCategory} selectedSubjectPhoto={selectedSubjectPhoto} />
      <VirtualTryOnDialog open={openVirtualTryOnDialog} handleClose={handleCloseVirtualTryOnDialog} selectedImageUrl={selectedImageUrl} selectedCategory={selectedCategory} selectedSubjectPhoto={selectedSubjectPhoto} />
    </Container>
  )
};

const VirtualTryOn: React.FC = () => {
  return (
    <Container maxWidth="md">
      <ProductGrid />
    </Container>
  );
};

export default VirtualTryOn;
