import React, { useState, useEffect, useRef } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, LinearProgress } from '@mui/material';
import Preview from './Preview';

import { callApiSubmitVirtualTryOnTask, callApiGetVirtualTryOnTaskStatus, API_SUCCESS, API_ERROR } from '../common/api';

const messages = [
    "Your outfit result will appear in seconds!",
    "The task is taking too long. Please try again later."
];

const OVERALL_TIMEOUT = 120000; // overall timeout for 2 minutes

const VirtualTryOnDialog = ({ handleClose, open, selectedImageUrl, selectedCategory, selectedSubjectPhoto }: any) => {
    const [selectedPhoto, setSelectedPhoto] = useState<String | null>(null);
    const [message, setMessage] = useState(messages[0]);
    const [progress, setProgress] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressVariant, setProgressVariant] = useState<"indeterminate" | "determinate" | "buffer" | "query" | undefined>("indeterminate");
    const [checkTaskStatusTimeout, setCheckTaskStatusTimeout] = useState<NodeJS.Timeout|null>(null);
    const [overallTimeout, setOverallTimeout] = useState<NodeJS.Timeout|null>(null);

    const runVirtualTryOnWithGarment = async (subjectPhoto: File, garmentUrl: string, category: string) => {
        setShowProgressBar(true);
        setProgressVariant("indeterminate");
        setProgress(0);
        try {
            const submitResponse = await callApiSubmitVirtualTryOnTask(subjectPhoto, garmentUrl, category);
            if (submitResponse.status === API_SUCCESS) {
                const taskId = submitResponse.data.task_id;

                const checkTaskStatus = async () => {
                    try {
                        const statusResponse = await callApiGetVirtualTryOnTaskStatus(taskId);
                        console.log(statusResponse)
                        if (statusResponse.status === API_SUCCESS) {
                            if (statusResponse.data.state === 'COMPLETED') {
                                setSelectedPhoto(statusResponse.data.virtual_try_on_photo_b64);
                                setShowProgressBar(false);
                                setMessage('');
                                clearTimeout(overallTimeout!);
                                setCheckTaskStatusTimeout(null);
                            } else {
                                if (statusResponse.data.state === 'PROGRESS') {
                                    if (statusResponse.data.info.progress > 0) {
                                        setProgressVariant("determinate");
                                        setProgress(statusResponse.data.info.progress);
                                    }
                                }
                                let checkTaskStatusTimeout = setTimeout(checkTaskStatus, 1000);
                                setCheckTaskStatusTimeout(checkTaskStatusTimeout);
                            }
                        } else if (statusResponse.status === API_ERROR) {
                            setMessage('An error occurred. Please try again.');
                            setShowProgressBar(false);
                            clearTimeout(overallTimeout!);
                            setCheckTaskStatusTimeout(null);
                        } else {
                            let checkTaskStatusTimeout = setTimeout(checkTaskStatus, 1000);
                            setCheckTaskStatusTimeout(checkTaskStatusTimeout);
                        }
                    } catch (error) {
                        setMessage('An error occurred. Please try again.');
                        setShowProgressBar(false);
                        clearTimeout(overallTimeout!);
                        setCheckTaskStatusTimeout(null);
                        console.error('Error during task status API call:', error);
                    }
                };
                checkTaskStatus();
                // Set the overall timeout
                let overallTimeout = setTimeout(() => {
                    setMessage(messages[1]);
                    setShowProgressBar(false);
                    setCheckTaskStatusTimeout(null);
                }, OVERALL_TIMEOUT);
                setOverallTimeout(overallTimeout);
            } else {
                console.log(submitResponse.message);
                setSelectedPhoto(null);
            }
        } catch (error) {
            console.error('Error during virtual try-on API call:', error);
        } finally {
            //
        }
    };

    const resetDialog = () => {
        setSelectedPhoto(null);
        setMessage(messages[0]);
        setProgress(0);
        setShowProgressBar(false);
        if (checkTaskStatusTimeout !== null) {
            clearTimeout(checkTaskStatusTimeout);
        }
        if (overallTimeout !== null) {
            clearTimeout(overallTimeout);
        }
    };

    useEffect(() => {
        console.log(`virtual try on useEffect 1: ${selectedSubjectPhoto}`);
        if (open && selectedSubjectPhoto) {
           
            runVirtualTryOnWithGarment(new File([selectedSubjectPhoto], "subject_photo"), selectedImageUrl, selectedCategory);
        }

        return () => {
            if (checkTaskStatusTimeout !== null) {
                clearTimeout(checkTaskStatusTimeout);
            }
            if (overallTimeout !== null) {
                clearTimeout(overallTimeout);
            }
        };
    }, [selectedSubjectPhoto]);

    return (
        <Dialog open={open} onClose={() => { resetDialog(); handleClose(); }} fullScreen>
            <DialogTitle style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}></DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                <Box style={{ flexGrow: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto' }}>
                    <Preview selectedPhoto={selectedPhoto} />
                </Box>
                <Typography variant="h6" style={{ flexGrow: 1, width: '40%', textAlign: 'center', marginTop: '5px' }}>
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                </Typography>
                {showProgressBar && (
                    <Box sx={{ width: '35%', marginTop: '5px' }}>
                        <LinearProgress variant={progressVariant} value={progress} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { resetDialog(); handleClose(); }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VirtualTryOnDialog;